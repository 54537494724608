import React from 'react';
import { Paper, Typography, Grid, Box, Skeleton, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';



const OverseasWarehouseAddressSkeleton = () => (
  <Paper elevation={0} 
  sx={{ marginBottom: 2, padding: 2, 
  // border: '1px solid #e0e0e0'
   }}>
    <Skeleton variant="text" width="40%" height={40} sx={{ mb: 2 }} />
    {[...Array(4)].map((_, index) => (
      <Skeleton key={index} variant="text" width="80%" />
    ))}
  </Paper>
);


const OverseasWarehouseAddress = ({ title, address, onCopy }) => {

  const fullAddress = address.join('\n');
  


  return (
  
  <Paper 
    elevation={0} 
    sx={{ 
      marginBottom: 2, 
      padding: 2,
      border: '1px solid #e0e0e0'  // Light grey border for each address
    }}
  >
    <Typography variant="h4" gutterBottom>{title}</Typography>
    <Box>
      {address.map((line, index) => (
        <Typography key={index} variant="body2">{line}</Typography>
      ))}
    </Box>
    <IconButton 
onClick={() => onCopy(fullAddress)}
        sx={{ position: 'absolute', top: 8, right: 8 }}
      >
        <ContentCopyIcon />
      </IconButton>
  </Paper>

    );
  };

const OverseasWarehouseAddresses = ({ userAccount, loading, onCopy }) => {

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        console.log('Address copied to clipboard');
        // You can add any other logic here that you want to happen after copying
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };

  if (loading) {
    return (
      <Grid container spacing={2} sx={{ border: '1px solid #e0e0e0', padding: 2 }}>
        <Grid item xs={12} sx={{ border: '1px dashed #bdbdbd', padding: 1 }}>
          <OverseasWarehouseAddressSkeleton />
        </Grid>
        <Grid item xs={12} sx={{ border: '1px dashed #bdbdbd', padding: 1 }}>
          <OverseasWarehouseAddressSkeleton />
        </Grid>
      </Grid>
    );
  }

  if (!userAccount || !userAccount.customer) {
    return <Typography>No shipping addresses available.</Typography>;
  }

  const airAddress = [
    `${userAccount.customer.user.firstName} ${userAccount.customer.user.lastName}`,
    '6858 NW 75th Street, Ste 4',
    userAccount.customer.customerNumber,
    'Miami, FL 33166'
  ];

  const seaAddress = [
    `JUNE LOGISTICS - ${userAccount.customer.user.firstName} ${userAccount.customer.user.lastName}`,
    '7185 NW 87TH AVE',
    'Miami Florida 33178',
    userAccount.customer.customerNumber,
    'Miami, FL 33178-1505'
  ];


  return (
    <Grid container spacing={2} sx={{ 
      
      // border: '1px solid #e0e0e0',
      
      padding: 2 }}>
      <Grid item xs={12} sx={{ border: '1px dashed #bdbdbd', padding: 1 }}>
        <OverseasWarehouseAddress 
          title="Shipping by air"
          address={airAddress}
          onCopy={handleCopy}
        />
      </Grid>
      <Grid item xs={12} sx={{ border: '1px dashed #bdbdbd', padding: 1 }}>
        <OverseasWarehouseAddress 
          title="Shipping by sea"
          address={seaAddress}
          onCopy={handleCopy}
        />
      </Grid>
    </Grid>
  );
};

export default OverseasWarehouseAddresses;
