import React, { useEffect, useState } from "react";
import "./productTable.scss";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";
import { CircularProgress, Button } from "@mui/material";
import Box from "@mui/material/Box";
import { MaterialReactTable } from 'material-react-table';


const AdminProductTable = ({ filterIds }) => {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedStatusMap, setSelectedStatusMap] = useState({});
  const token = useSelector((state) => state.auth.user.accessToken);

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
    return date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  };

  const fetchData = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get("/api/v1/user/warehouse/get/all/products", config);

      let data = response.data;

      // Filter data based on filterIds if provided
      if (filterIds && filterIds.length > 0) {
        data = data.filter((item) => filterIds.includes(item.junId));
      }

      // Sort data by dateAdded from most recent to least recent
      data.sort((a, b) => new Date(b.dateAdded) - new Date(a.dateAdded));

      setTableData(data);
      setIsLoading(false); // Set loading state to false when the data is loaded
    } catch (error) {
      if (error.response) {
        console.error("Error status:", error.response.status);
        console.error("Error data:", error.response.data);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error message:", error.message);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [filterIds]);

  const convertToXML = (data) => {
    let xml = '<?xml version="1.0" encoding="UTF-8"?>\n<tableData>\n';
    data.forEach((row) => {
      xml += `  <row>\n`;
      Object.keys(row).forEach((key) => {
        xml += `    <${key}>${row[key]}</${key}>\n`;
      });
      xml += `  </row>\n`;
    });
    xml += "</tableData>";
    return xml;
  };

  const downloadXML = () => {
    const xmlData = convertToXML(tableData);
    const blob = new Blob([xmlData], { type: "application/xml" });
    const href = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = "tableData.xml";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  const exportPDF = () => {
    const doc = new jsPDF("landscape");

    doc.setFontSize(12);

    const dataWithConsignee = tableData.map((item) => ({
      ...item,
      consignee: `${item.customerFirstName} ${item.customerLastName} C/O JUNE LOGISTICS`,
    }));

    const exportColumns = columns
      .filter((column) => column.accessorKey !== "action" && column.accessorKey !== "invoice")
      .map((column) => ({
        header: column.header,
        dataKey: column.accessorKey,
      }));

    autoTable(doc, {
      columns: exportColumns,
      body: dataWithConsignee,
      styles: {
        fontSize: 8,
        cellWidth: "wrap",
      },
      columnStyles: {
        id: { cellWidth: 40 },
        shipper: { cellWidth: 60 },
        consignee: { cellWidth: 60 },
      },
      didDrawPage: (data) => {
        doc.text("My Table Data", 20, 10);
      },
    });

    doc.save("tableData.pdf");
  };

  const exportToExcel = () => {
    const dataWithConsignee = tableData.map((item) => ({
      "Date Added": formatDate(item.createdOn),
      "ID": item.junId,
      "Shipper": item.supplierName,
      "Description": item.description,
      "Weight": item.weight,
      "Mailbox": item.customerNumber,
      "Consignee": `${item.customerFirstName} ${item.customerLastName} C/O JUNE LOGISTICS`,
      "Tracking Number": item.trackingNumber,
    }));
  
    const ws = XLSX.utils.json_to_sheet(dataWithConsignee);
  
    // Set the date column to have a date format in Excel
    const dateCol = XLSX.utils.encode_col(0); // Assuming "Date Added" is the first column
    for (let i = 2; i <= dataWithConsignee.length + 1; i++) {
      const cell = ws[`${dateCol}${i}`];
      if (cell && cell.v !== 'Invalid Date') {
        cell.t = 'd';
        cell.v = new Date(cell.v);
        cell.z = 'yyyy-mm-dd';
      }
    }
  
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "AirwayManifest");
    XLSX.writeFile(wb, "AirwayManifest.xlsx");
  };

  const columns = [
    { accessorKey: "junId", header: "ID", size: 130 },
    {
      accessorKey: "createdOn",
      header: "Date Added",
      size: 150,
      Cell: ({ cell }) => {
        const dateValue = cell.getValue();
        const date = new Date(dateValue);
        if (isNaN(date)) {
          return "Invalid Date";
        }
        return date.toLocaleDateString(); // Format the date as needed
      },
    },
    {
      accessorKey: "action",
      header: "Action",
      size: 300,
      Cell: ({ row }) => {
        const productId = row.original.id;
        const currentStatus = row.original.packageStatus;

        const handleClick = (productId, status, customerNumber) => {
          const isConfirmed = window.confirm(`Are you sure you want to send this update to customer: ${customerNumber}?`);

          if (!isConfirmed) {
            return;
          }

          setSelectedStatus(status);

          axios
            .put("/api/v1/user/warehouse/update/product/status", null, {
              params: {
                productId: productId,
                status: status,
              },
            })
            .then((response) => {
              console.log(response);
              fetchData();
            })
            .catch((error) => console.error(error));
        };

        return (
          <div className="cellAction">
            <select
              className="dropdown"
              onChange={(e) => {
                const selectedStatus = e.target.value;
                handleClick(productId, selectedStatus, row.original.customerNumber);
              }}
              value={selectedStatusMap[productId] || currentStatus}
            >
              <option value="CREATED">At Miami</option>
              <option value="SENT_OFF">At Miami Airport</option>
              <option value="TAKEN_OFF">On flight to Jamaica</option>
              <option value="LANDED">At Customs</option>
              <option value="TRANSIT_TO_LOCAL_WAREHOUSE">In transit to JUNE Warehouse</option>
              <option value="PROCESSING">At JUNE</option>
              <option value="READY_FOR_DELIVERY">Available for Delivery</option>
              <option value="READY_FOR_PICKUP">Available for Collection at JUNE</option>
              <option value="PICKED_UP">Package Collected</option>
              <option value="OUT_FOR_DELIVERY">On route for delivery</option>
              <option value="DELIVERED">Package Delivered</option>
              <option value="DELAYED">Delayed</option>

            </select>
          </div>
        );
      },
    },
    { accessorKey: "supplierName", header: "Shipper", size: 130 },
    
    {
      accessorKey: "description",
      header: "Description",
      size: 300,
      Cell: ({ cell }) => <span>{toTitleCase(cell.getValue())}</span>,
    },
    { accessorKey: "weight", header: "Weight", size: 130 },
    { accessorKey: "customerNumber", header: "Mailbox", size: 150 },
    {
      accessorKey: "consignee",
      header: "Consignee",
      size: 400,
      Cell: ({ row }) => (
        <div>
          {row.original.customerFirstName} {row.original.customerLastName} C/O JUNE LOGISTICS
        </div>
      ),
    },
    {
      accessorKey: "invoice",
      header: "Invoice",
      size: 60,
      Cell: ({ row }) => (
        <div>
          {row.original.preAlert && row.original.preAlert.startsWith("https") ? (
            <a href={row.original.preAlert} target="_blank" rel="noopener noreferrer">
              View
            </a>
          ) : (
            <span>None</span>
          )}
        </div>
      ),
    },
    { accessorKey: "trackingNumber", header: "Tracking Number", size: 200 },
  
  ];

  return (
    <>
      <div className="grid">
        {/* <Sidebar /> */}
        <Box marginTop={2} marginBottom={2} marginLeft={1}>
          <Button variant="outlined" color="primary" onClick={downloadXML} sx={{ marginRight: 1 }}>
            Export as XML
          </Button>
          <Button variant="outlined" color="primary" onClick={exportPDF} sx={{ marginRight: 1 }}>
            Export as PDF
          </Button>
          <Button variant="outlined" color="primary" onClick={exportToExcel}>
            Export as Excel
          </Button>
        </Box>
        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            <CircularProgress style={{ color: "green" }} />
          </Box>
        ) : (
          <MaterialReactTable
            columns={columns}
            data={tableData}
            muiTablePaperProps={{
              sx: {
                marginLeft: 0.9

              },
            }}
            muiTableBodyCellProps={{
              sx: {
                "&:hover": {
                  cursor: "pointer",
                },

              },
            }}
            muiTableContainerProps={{
              sx: {
                // maxHeight: 600,
                maxHeight:(theme) => theme.breakpoints.down('sm') ? '600px' : '0', // Add bottom padding in mobile view
              },
            }}
            muiTablePaginationProps={{
    rowsPerPageOptions: [10, 20, 30],
    SelectProps: {
      style: {
        fontFamily: 'Lato, sans-serif',
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#333',
        backgroundColor: '#f5f5f5',
        borderRadius: '4px',
        padding: '8px 16px',
      },
    },
    labelRowsPerPage: 'Items per page:',
    rowsPerPageDropdownAriaLabel: 'Items per page',
    rowsPerPageDropdownPosition: 'top',
    showFirstButton: true,
    showLastButton: true,
    firstIconButtonText: 'First',
    previousIconButtonText: 'Previous',
    nextIconButtonText: 'Next',
    lastIconButtonText: 'Last',
    labelDisplayedRows: ({ from, to, count }) => `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`,
  }}
            initialState={{
              pagination: { pageIndex: 0, pageSize: 12 },
              sorting: [{ id: "dateAdded", desc: true }],
            }}
            enableStickyHeader // Add this prop to enable sticky headers
            muiTableHeadCellProps={{
              sx: {
                backgroundColor: '#f5f5f5', // Optional: Set the background color for header cells
                zIndex: 1, // Ensure the header cells are above other elements
              },
            }}
            muiTableProps={{
              sx: {
                boxShadow: 'none', // Remove the box shadow
                borderRadius: '0px', // Remove the border radius

              },
            }}
          />
        )}
        {/* <BottomBar /> */}
      </div>
    </>
  );
};

export default AdminProductTable;