import React, { useEffect, useState } from "react";
import "./NavAppWarehouse.scss";
import HomeIcon from "@mui/icons-material/Home";
import CardTravelIcon from "@mui/icons-material/CardTravel";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Link, useNavigate } from "react-router-dom";
import { logout, reset } from "../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Grid, Button, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const NavAppWarehouse = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isAuthenticated } = useSelector((state) => state.auth);
    const [isCollapsed, setIsCollapsed] = useState(false);

    const logoutHandler = async () => {
      try {
        await dispatch(logout()).unwrap();
        await dispatch(reset());
        navigate("/login");
      } catch (error) {
        console.error("An error occurred during logout: ", error);
      }
    };

    useEffect(() => {
      if (!isAuthenticated) {
        navigate("/login");
      }
    }, [isAuthenticated, navigate]);

    useEffect(() => {
      const handleResize = () => {
        setIsCollapsed(window.innerWidth <= 768); // Adjust the breakpoint as needed
      };

      window.addEventListener('resize', handleResize);
      handleResize(); // Call once to set initial state

      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
      <Grid className="sidebar">
        <div className="sidebar__menu">
          <ul className="sidebar__menu-list">
            <Button
              component={RouterLink}
              to="/warehouse/dash/products"
              variant="outlined"
              size="large"
              sx={{
                textTransform: "none",
                width: isCollapsed ? "48px" : "90%",
                minWidth: isCollapsed ? "48px" : "auto",
                height: isCollapsed ? "48px" : "auto",
                justifyContent: "center",
                padding: isCollapsed ? "8px" : "10px",
                margin: "20px auto",
                boxShadow: "0px 0.6px 2px rgba(0, 0, 0, 0.1)",
                display: "flex",
                borderRadius: isCollapsed ? "50%" : 6,
                aspectRatio: isCollapsed ? "1 / 1" : "auto",
              }}
            >
              {isCollapsed ? (
                <AddCircleIcon />
              ) : (
                <Typography 
                  variant="caption" 
                  display="block" 
                  align="center"
                >
                  Add a New Package
                </Typography>
              )}
            </Button>
            <Link to="/warehouse/dash">
              <li className="sidebar__menu-item" data-tooltip="Home">
                <FontAwesomeIcon icon={faHome} className="sidebar__icon" aria-label="Home" />
                <span>Home</span>
              </li>
            </Link>
            <Link to="/warehouse/dash/products/viewProducts">
              <li className="sidebar__menu-item" data-tooltip="Manifest">
                <CardTravelIcon className="sidebar__icon" aria-label="Manifest" />
                <span>Manifest</span>
              </li>
            </Link>
            <li className="sidebar__menu-item" data-tooltip="SIGN OUT" onClick={logoutHandler}>
              <ExitToAppIcon className="sidebar__icon" aria-label="Sign Out" />
              <span>SIGN OUT</span>
            </li>
          </ul>
        </div>
      </Grid>
    );
};

export default NavAppWarehouse;