// features/auth/authSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";
import AuthService from "../../components/services/auth.service";

export const register = createAsyncThunk(
  "auth/register",
  async (userData, thunkAPI) => {
    try {
      let registerFunction;
      switch (userData.role) {
        case "PARTNER":
          registerFunction = AuthService.registerPartner;
          break;
        case "CUSTOMER":
          registerFunction = AuthService.registerCustomer;
          break;
        case "COURIER":
          registerFunction = AuthService.registerCourier1;
          break;
        default:
          return thunkAPI.rejectWithValue("Invalid user role");
      }
      const response = await registerFunction(userData);

      return { user: response, role: userData.role };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }, thunkAPI) => {
    try {
      const data = await AuthService.login(email, password);
      const decodedToken = jwt_decode(data.accessToken);
      localStorage.setItem("userRole", JSON.stringify(decodedToken.role));
      return { user: data, role: decodedToken.role };
    } catch (error) {
      let message = "An error occurred during login.";
      // Check if the error has a response and a data object with a message.
      if (
        error.response &&
        error.response.data &&
        error.response.data.accessToken
      ) {
        if (
          error.response.data.accessToken === "This account is not verified."
        ) {
          message =
            "Verify your account to continue. Please check you inbox or spam for the verification link.";
        } else if (
          error.response.data.accessToken === "Invalid username or password"
        ) {
          message =
            "Either the email or password is incorrect. Please try again.";
        } else message = error.response.data.accessToken;
      } else if (error.message) {
        message = error.message;
      }
      console.error("Error:", message);
      // Use rejectWithValue to pass the custom error message to the component.
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
  try {
    await AuthService.logout();
    localStorage.removeItem("userRole");
    localStorage.removeItem("accessToken");
    // If you also store the user object in localStorage, remove it here as well
    localStorage.removeItem("user");
    return {};
  } catch (error) {
    const message = error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});


let user = null;
let role = null;
let token = null;

try {
  const storedUser = localStorage.getItem("user");
  if (storedUser) {
    user = JSON.parse(storedUser);
    role = user.role; // Assuming the role is part of the user object stored in localStorage
    token = user.accessToken; // Assuming the accessToken is part of the user object
  }
} catch (error) {
  console.error("Error parsing data from localStorage:", error);
}

const initialState = {
  user: user,
  role: role,
  token: token,
  isError: false,
  isAuthenticated: !!token, // Update isAuthenticated based on the presence of a token
  isSuccess: false,
  isLoading: false,
  message: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
    updateUserRole: (state, action) => {
      state.role = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload.user;
        state.role = action.payload.role;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload.user;
        state.role = action.payload.role;
        state.isAuthenticated = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
        state.isAuthenticated = false;
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
        state.isAuthenticated = false;
      });
  },
});

export const { reset, updateUserRole } = authSlice.actions;

export default authSlice.reducer;
