// BottomBar.jsx
import React, { useEffect, useState } from "react";
import "./bottomBar.scss";
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout, reset } from "../../../features/auth/authSlice";

const BottomBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [logoutTimer, setLogoutTimer] = useState(null);

  const logoutHandler = async () => {
    try {
      await dispatch(logout()).unwrap();
      await dispatch(reset());
      navigate("/login");
    } catch (error) {
      console.error("An error occurred during logout: ", error);
    }
  };

  const startLogoutTimer = () => {
    const timer = setTimeout(() => {
      logoutHandler();
    }, 300000); // 1 minute (adjust the duration as needed)
    setLogoutTimer(timer);
  };

  const clearLogoutTimer = () => {
    if (logoutTimer) {
      clearTimeout(logoutTimer);
      setLogoutTimer(null);
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        startLogoutTimer();
      } else {
        clearLogoutTimer();
      }
    };

    const handleResize = () => {
      if (window.outerWidth === 0 || window.outerHeight === 0) {
        logoutHandler();
      }
    };

    const handleBeforeUnload = (event) => {
      const navigationEntries = performance.getEntriesByType("navigation");
      if (navigationEntries.length > 0) {
        const navigationType = navigationEntries[0].type;
        if (navigationType === "reload") {
          return;
        }
      }
      logoutHandler();
      event.returnValue = "";
    };
    

    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("resize", handleResize);
    window.addEventListener("beforeunload", handleBeforeUnload);


    return () => {
      clearLogoutTimer();
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.addEventListener("resize", handleResize);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <div className="BottomBar">
      <div className="center">
        <ul className="dash_links">
          <Link to="/customer/dash">
            <li>
              <HomeIcon className="icon" />
              <span>Home</span>
            </li>
          </Link>
          <Link to="/customer/dash/invoices">
            <li>
              <ReceiptIcon className="icon" />
              <span>Packages</span>
            </li>
          </Link>
          <Link to="/customer/dash/profile">
            <li>
              <AccountCircleOutlinedIcon className="icon" />
              <span>Account</span>
            </li>
          </Link>
          <Link>
            <li onClick={logoutHandler}>
              <ExitToAppIcon className="icon" />
              <span>Sign Out</span>
            </li>
          </Link>
        </ul>
      </div>
    </div>
  );
};

export default BottomBar;