import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from "react-redux";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { styled, css } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import Fade from '@mui/material/Fade';
import { Button as BaseButton } from '@mui/base/Button';
import Box from '@mui/material/Box';
import { TextField, Stack, Typography, CircularProgress, Chip, Grid, FormControl, LinearProgress, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Tooltip from '@mui/material/Tooltip';
import InspirationQuote from './InspirationQuote';



import './CustomerStatusStream.scss'
import NavAppCustomer from "../../../components/NavAppSidebar/NavAppCustomer";
import BottomBar from '../../../components/customers/bottombar/BottomBar';
import InvoiceUploadModal from '../../../components/customers/InvoiceUploadModal';
// import { Grid } from 'antd';

const CustomerStatusStream = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isLoading, setIsLoading] = useState(true);
  const [packagesData, setPackagesData] = useState([]);
  const [userData, setUserData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [message, setMessage] = useState("");
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [file, setFile] = useState(null);
  const [shouldReload, setShouldReload] = useState(false);
  const [hoveredButtonId, setHoveredButtonId] = useState(null);
  const [statusFilter, setStatusFilter] = useState('IN_TRANSIT');
  const [packageIndex, setPackageIndex] = useState({});
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSupplierName, setSelectedSupplierName] = useState(null);
  const [selectedDescription, setSelectedDescription] = useState(null);
  const [selectedTrackingNumber, setSelectedTrackingNumber] = useState(null);
  const [monthlyStats, setMonthlyStats] = useState({});

  const token = useSelector((state) => state.auth.user?.accessToken);

  const getCurrentMonthStats = () => {
    const now = new Date();
    const currentMonthYear = `${now.getMonth() + 1}-${now.getFullYear()}`;
    return monthlyStats[currentMonthYear] || { count: 0, weight: 0 };
  };

  const getMonthlyShippingProgress = () => {
    const currentStats = getCurrentMonthStats();
    const packagesShipped = currentStats.count;
    const progress = Math.min(packagesShipped / 20, 1);
    const remaining = Math.max(20 - packagesShipped, 0);
    const goalReached = packagesShipped >= 20;
    return { progress, remaining, goalReached };
  };

  const getTimeOfDay = () => {
    const hour = new Date().getHours();
    if (hour < 5) return "night";
    if (hour < 12) return "morning";
    if (hour < 18) return "afternoon";
    if (hour < 22) return "evening";
    return "night";
  };

  const ShippingGoalProgress = () => {
    const { progress, remaining, goalReached } = getMonthlyShippingProgress();
    
    return (
      <Paper elevation={0} sx=
      {{ p: 2, mb: 2, 
        '--Grid-borderWidth': '1px',
      }}>
        {/* backgroundColor: '#f0f8ff'  */}
        <Typography variant="h1" >
          Monthly Shipping Goal
        </Typography>
        <LinearProgress 
          variant="determinate" 
          value={progress * 100} 
          sx={{ 
            height: 10, 
            borderRadius: 10,
            backgroundColor: '#e0e0e0',
            '& .MuiLinearProgress-bar': {
              backgroundColor: goalReached ? '#4caf50' : '#2196f3',
            },
          }} 
        />
        <Typography variant="caption" sx={{ mt: 1 }}>
          {goalReached 
            ? "Congratulations! You've reached your monthly shipping goal and qualified for a discount/gift card!"
            : `Ship ${remaining} more package${remaining !== 1 ? 's' : ''} this month to qualify for a discount/gift card!`
          }
        </Typography>
      </Paper>
    );
  };
  const MonthlyShippingOverview = ({ stats, goalProgress }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const getCurrentMonth = () => {
      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      const now = new Date();
      return months[now.getMonth()];
    };
  
    return (
      <Paper elevation={0} sx={{ p: 2, mb: 2, backgroundColor: '#f5f5f5', borderRadius: '8px' }}>
        <Box sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: isMobile ? 'stretch' : 'center',
          gap: 2,
          mb: 2,
        }}>
           <Typography variant="h4" sx={{ fontWeight: 'bold', flexGrow: 1 }}>
         Purchases in {getCurrentMonth()} : <strong>{stats.count}</strong>
        </Typography>
          <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', justifyContent: isMobile ? 'flex-start' : 'flex-end' }}>
            {/* <Typography variant="body2">
              Number of Purchases in   {getCurrentMonth()} : <strong>{stats.count}</strong>
            </Typography> */}
            <Typography variant="body2">
              Total Weight in  {getCurrentMonth()} : <strong>{stats.weight.toFixed(0)} lbs</strong>
            </Typography>
          </Box>
        </Box>
  
        <Box sx={{ mt: 2 }}>
        <Typography variant="h6" sx={{ mt: 1, display: 'block' }}>
            {goalProgress.goalReached 
              ? "Congratulations! You've reached your monthly shipping goal and qualified for our discount/gift card!"
              : `Purchase ${goalProgress.remaining} more package${goalProgress.remaining !== 1 ? 's' : ''} this month to qualify for a discount/gift card!`
            }
          </Typography>
          <Typography variant="caption" gutterBottom>
          Milestone Path
          </Typography>
          <LinearProgress 
            variant="determinate" 
            value={goalProgress.progress * 100} 
            sx={{ 
              height: 10, 
              borderRadius: 5,
              backgroundColor: '#e0e0e0',
              '& .MuiLinearProgress-bar': {
                backgroundColor: goalProgress.goalReached ? '#4caf50' : '#00fd3d',
              },
            }} 
          />
          
        </Box>
      </Paper>
    );
  };

  useEffect(() => {
    if (token) {
      const decodedToken = jwt_decode(token);
      axios
        .get(`/api/v1/user/get/by/email?email=${decodedToken.userEmail}`)
        .then((response) => {
          setUserData(response.data);
        })
        .catch((error) => {
          console.error("Error fetching user data: ", error);
        });
    }
  }, [token]);

  useEffect(() => {
    if (userData && userData.customer) {
      fetchData();
    }
  }, [userData]);

  useEffect(() => {
    if (shouldReload) {
      fetchData();
      setShouldReload(false);
    }
  }, [shouldReload]);

  const calculateMonthlyStats = (data) => {
    const stats = {};
    data.forEach(product => {
      const date = new Date(product.createdOn);
      const monthYear = `${date.getMonth() + 1}-${date.getFullYear()}`;
      if (!stats[monthYear]) {
        stats[monthYear] = { count: 0, weight: 0 };
      }
      stats[monthYear].count += 1;
      stats[monthYear].weight += parseFloat(product.weight) || 0;
    });
    return stats;
  };

  const fetchData = async () => {
    if (userData && userData.customer) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get(
          "/api/v1/user/warehouse/get/all/products",
          config
        );
        
        const userPackageData = response.data.filter(
          (product) => product.customerNumber === userData.customer.customerNumber
        );
        
        const deliveredPackages = userPackageData.filter(pkg => pkg.packageStatus === "DELIVERED");
        const inTransitPackages = userPackageData.filter(pkg => pkg.packageStatus !== "DELIVERED");
        
        const sortedInTransitPackages = inTransitPackages.sort((a, b) => new Date(b.updatedOn) - new Date(a.updatedOn));
        const sortedDeliveredPackages = deliveredPackages.sort((a, b) => new Date(b.updatedOn) - new Date(a.updatedOn));

        const index = {
          IN_TRANSIT: sortedInTransitPackages,
          DELIVERED: sortedDeliveredPackages
        };

        setPackagesData([...sortedInTransitPackages, ...sortedDeliveredPackages]);
        setPackageIndex(index);
        setMonthlyStats(calculateMonthlyStats(userPackageData));
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    }
  };

  const toTitleCase = (str) => {
    if (!str) return str;
    const acronyms = ['USA', 'EU', 'NASA', 'TV'];
    return str
      .split(' ')
      .map(word => {
        if (acronyms.includes(word.toUpperCase())) {
          return word.toUpperCase();
        } else {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }
      })
      .join(' ');
  };

  const calculateShippingPrice = (weight) => {
    if (!weight) return "To be calculated";
    const numWeight = Number(weight);
    const price = ((numWeight * 3) + 1 + 1.50) * 159;
    return price.toFixed(2);
  };

  const getStatusClassName = (packageStatus) => {
    switch (packageStatus) {
      case "CREATED": return "received";
      case "SHIPPED": return "shipped";
      case "READY_FOR_DELIVERY": return "ready";
      case "DELIVERED": return "delivered";
      case "READY_FOR_PICKUP": return "pickupReady";
      case "OUT_FOR_DELIVERY": return "delivery";
      case "PICKED_UP": return "pickedUp";
      case "SENT_OFF": return "sentOff";
      case "LANDED": return "landed";
      case "PROCESSING": return "processing";
      case "TRANSIT_TO_LOCAL_WAREHOUSE": return "transit";
      case "TAKEN_OFF": return "takenOff";
      case "DELAYED": return "delayed";
      default: return "";
    }
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case "CREATED": return "At Miami";
      case "READY_FOR_DELIVERY": return "Available for Delivery";
      case "DELIVERED": return "Delivered";
      case "OUT_FOR_DELIVERY": return "En route for delivery";
      case "READY_FOR_PICKUP": return "Available for Collection";
      case "PICKED_UP": return "Collected";
      case "SENT_OFF": return "At Miami Airport";
      case "LANDED": return "At Customs";
      case "PROCESSING": return "At JUNE";
      case "TRANSIT_TO_LOCAL_WAREHOUSE": return "On route to JUNE";
      case "TAKEN_OFF": return "On flight to Jamaica";
      case "DELAYED": return "delayed";
      default: return status;
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setUploadSuccess(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSelectClick = (packageItem) => {
    if (packageItem.preAlert && packageItem.preAlert.startsWith("https")) {
      setSelectedProductId(packageItem.id);
      setOpenConfirmDialog(true);
    } else {
      setSelectedProductId(packageItem.id);
      setIsModalOpen(true);
    }
    setSelectedSupplierName(packageItem.supplierName);
    setSelectedDescription(packageItem.description);
    setSelectedTrackingNumber(packageItem.trackingNumber);
  };

  const handleConfirmUpload = () => {
    setOpenConfirmDialog(false);
    setIsModalOpen(true);
  };

  const handleCancelUpload = () => {
    setOpenConfirmDialog(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    setUploadSuccess(false);

    if (!file || !selectedProductId) {
      setMessage("Please select a product and upload a file.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.put(
        `/api/v1/user/customer/${selectedProductId}/upload/preAlert`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setMessage(response.data);
      setUploadSuccess(true);
      setShouldReload(true);
    } catch (error) {
      console.error("File upload failed:", error);
      if (error.response && error.response.status === 403) {
        setMessage("Product does not exist");
      } else {
        setMessage(error.response ? error.response.data : "Error uploading file");
      }
      setUploadSuccess(false);
    }
  };


  const filteredPackages = useMemo(() => {
    const statusFiltered = packageIndex[statusFilter] || [];
    if (!searchTerm) return statusFiltered;
    
    return statusFiltered.filter(pkg => 
      pkg.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
      pkg.supplierName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      pkg.packageStatus.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [statusFilter, packageIndex, searchTerm]);


  return (
    <div className="customer_dash" style={{ position: 'relative', overflow: 'hidden' }}>
      <NavAppCustomer />
      <div className="customer_container">
        <div className="content_area">
          <Box sx={{ 
            position: 'sticky', 
            top: 0, 
            zIndex: 100, 
            backgroundColor: 'white', 
            padding: '10px',
            borderBottom: '1px solid #e0e0e0',
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'stretch' : 'center',
            justifyContent: 'space-between',
            gap: 2
          }}>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: isMobile ? 'flex-start' : 'center',
              gap: 1
            }}>
              <Typography 
                variant={isMobile ? "subtitle1" : "h6"} 
                sx={{ marginRight: isMobile ? 0 : 2 }}>
                Filter by Status:
              </Typography>
              <Stack 
                direction="row" 
                sx={{ flexWrap: 'wrap', gap: 1 }}>
                <Chip
                  label="On route"
                  onClick={() => setStatusFilter('IN_TRANSIT')}
                  sx={{
                    backgroundColor: statusFilter === 'IN_TRANSIT' ? '#fbeb3c' : 'default',
                    '&:hover': {
                      backgroundColor: '#fbeb3c',
                    },
                    borderRadius: '4px',
                  }}
                />
                <Chip
                  label="Delivered"
                  onClick={() => setStatusFilter('DELIVERED')}
                  sx={{
                    backgroundColor: statusFilter === 'DELIVERED' ? '#00fd3d' : 'default',
                    '&:hover': {
                      backgroundColor: '#00fd3d',
                    },
                    borderRadius: '4px',
                  }}
                />
              </Stack>
            </Box>
            <TextField
              label="Search"
              variant="filled"
              size="large"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{
                width: isMobile ? '100%' : '300px',
                '& .MuiFilledInput-root': {
                  border: 'none',
                  backgroundColor: 'rgba(0, 0, 0, 0.06)',
                  borderRadius: '10px',
                  '&:hover, &.Mui-focused': {
                    backgroundColor: 'rgba(0, 0, 0, 0.09)',
                  },
                  '&:before, &:after': {
                    display: 'none',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'grey.700',
                  fontSize: '1rem',
                },
                '& .MuiFilledInput-input': {
                  padding: '20px 25px',
                },
              }}
            />
          </Box>
          <div className="scrollable_content">
            {isLoading ? (
              <Box style={{ 
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0,
              }}>
                <CircularProgress style={{ color: "green" }} />
              </Box>
            ) : (
              <Box>
 {/* <MonthlyShippingOverview 
                  stats={getCurrentMonthStats()} 
                  goalProgress={getMonthlyShippingProgress()} 
                /> */}

                {filteredPackages.length > 0 ? (
                  filteredPackages.map((packageItem) => (
                    <Box
                      key={packageItem.id}
                      className="package_tweet"
                    >
                      <Box className="package_header">
                        <Stack spacing={2} className="responsive_stack">
                          <TextField
                            label="Description"
                            variant="outlined"
                            fullWidth
                            value={toTitleCase(packageItem.description)}
                            className="package_description"
                            InputProps={{
                              readOnly: true,
                            }}
                            sx={compactTextFieldStyle}
                          />
                  
                          <TextField 
                            label="Supplier Name"
                            variant="outlined"
                            fullWidth
                            value={packageItem.supplierName}
                            className="package_delivery_date"
                            InputProps={{
                              readOnly: true,
                            }}
                            sx={compactTextFieldStyle}
                          />

                          <Tooltip title={packageItem.trackingNumber || "Not available"} arrow>
                            <TextField
                              label="Tracking Number"
                              variant="outlined"
                              fullWidth
                              multiline
                              maxRows={3}
                              value={packageItem.trackingNumber || "Not available"}
                              className="package_tracking_number"
                              InputProps={{
                                readOnly: true,
                              }}
                              sx={{
                                '& .MuiInputBase-input': {
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  display: '-webkit-box',
                                  WebkitLineClamp: 3,
                                  WebkitBoxOrient: 'vertical',
                                  ...compactTextFieldStyle
                                },
                              }}
                            />
                          </Tooltip>
                          <TextField
                            label="Weight (lbs)"
                            variant="outlined"
                            fullWidth
                            value={packageItem.weight ? `${packageItem.weight} ${Number(packageItem.weight) === 1 ? 'lb' : 'lbs'}` : "To be calculated"}
                            className="package_weight"
                            InputProps={{
                              readOnly: true,
                            }}
                            sx={compactTextFieldStyle}
                          />
                          <TextField
                            label={`Estimated Price (${packageItem.weight} ${Number(packageItem.weight) === 1 ? 'lb' : 'lbs'})`}
                            variant="outlined"
                            fullWidth
                            value={packageItem.weight 
                              ? `$${calculateShippingPrice(packageItem.weight)}` 
                              : "To be calculated"}                         
                            className="package_shipping_price"
                            InputProps={{
                              readOnly: true,
                            }}
                            sx={compactTextFieldStyle}
                          />
                        </Stack>
                        <Box className="status_container">
                          <Box className="status_row" sx={{ width: '100%' }}>
                            <Chip
                              className={`package_status ${getStatusClassName(packageItem.packageStatus)}`}
                              label={getStatusLabel(packageItem.packageStatus)}
                              sx={{
                                maxWidth: '100%',
                                height: 'auto',
                                '& .MuiChip-label': {
                                  display: 'block',
                                  whiteSpace: 'normal',
                                  wordWrap: 'break-word',
                                  lineHeight: 1.2,
                                },
                              }}
                            />
                          </Box>
                          <Box className="status_row">
                            <Typography variant="caption" display="block" gutterBottom>
                              Status as of {formatDate(packageItem.updatedOn)}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <div>
                        <button
                          className={`uploadButton ${
                            packageItem.preAlert &&
                            packageItem.preAlert.startsWith("https")
                              ? "uploaded"
                              : ""
                          }`}
                          onClick={() => handleSelectClick(packageItem)}
                          onMouseEnter={() =>
                            setHoveredButtonId(packageItem.id)
                          }
                          onMouseLeave={() => setHoveredButtonId(null)}
                        >
                          {packageItem.preAlert &&
                          packageItem.preAlert.startsWith("https")
                            ? hoveredButtonId === packageItem.id
                              ? "Edit Invoice"
                              : "Invoice Added"
                            : "Add Invoice"}
                        </button>
                      </div>
                    </Box>
                  ))
                ) : (

                  <Grid container spacing={2} justifyContent="center" alignItems="center">
    <Grid item 
    xs={12} 
    md={8} 
    lg={12}>
      <Paper 
      elevation={0} 
      sx={{ p: 3, 
      backgroundColor: 'transparent' }}>
        <Typography variant="h2" gutterBottom>
          Good {getTimeOfDay()}, {userData?.customer?.user?.firstName || 'valued customer'}!
        </Typography>
        {/* <Typography variant="caption">
        {userData?.customer?.customerNumber || 'Not available'}

        </Typography> */}
        <InspirationQuote />
      </Paper>
    </Grid>
  </Grid>

                


               
                )}
              </Box>
            )}
          </div>
        </div>
        <BottomBar />
      </div>
      {isModalOpen && (
        <InvoiceUploadModal
          productId={selectedProductId}
          handleFileChange={handleFileChange}
          supplierName={selectedSupplierName}
          description={selectedDescription}
          trackingNumber={selectedTrackingNumber}
          handleSubmit={handleSubmit}
          closeModal={handleCloseModal}
          message={message}
          uploadSuccess={uploadSuccess}
        />
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openConfirmDialog}
        onClose={handleCancelUpload}
        closeAfterTransition
        slots={{ backdrop: StyledBackdrop }}
        sx={{ 
          position: 'fixed', 
          top: 0, 
          left: 0, 
          right: 0, 
          bottom: 0, 
          zIndex: 1400 
        }}
      >
        <Fade in={openConfirmDialog}>
          <ModalContent sx={style}>
            <p id="transition-modal-description" className="modal-description">
              An invoice has been added. Want to add new one?
            </p>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <TriggerButton onClick={handleCancelUpload} sx={{ mr: 1 }}>
                Cancel
              </TriggerButton>
              <TriggerButton onClick={handleConfirmUpload} variant="contained">
                Add New Invoice
              </TriggerButton>
            </Box>
          </ModalContent>
        </Fade>
      </Modal>
    </div>
  );
};

// Styled components for the modal
const Backdrop = React.forwardRef((props, ref) => {
  const { open, ...other } = props;
  return (
    <Fade in={open}>
      <div ref={ref} {...other} />
    </Fade>
  );
});

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1400;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: 1399;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  zIndex: 1401,
};

const ModalContent = styled('div')(
  ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
    padding: 24px;
    color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
      margin-bottom: 4px;
    }
  `,
);

const TriggerButton = styled(BaseButton)(
  ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5;
    padding: 8px 16px;
    border-radius: 8px;
    transition: all 150ms ease;
    cursor: pointer;
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

    &:hover {
      background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
    }

    &:active {
      background: ${theme.palette.mode === 'dark' ? grey[700] : grey[100]};
    }

    &:focus-visible {
      box-shadow: 0 0 0 4px ${theme.palette.mode === 'dark' ? blue[300] : blue[200]};
      outline: none;
    }
  `,
);

// Define color constants
const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const blue = {
  200: '#99CCFF',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0066CC',
};

const compactTextFieldStyle = {
  '& .MuiInputBase-root': {
    height: '36px',
  },
  '& .MuiInputBase-input': {
    fontSize: '0.8rem',
    padding: '3px 13px',
  },
  '& .MuiInputLabel-root': {
    fontSize: '0.875rem',
    transform: 'translate(14px, 12px) scale(1)',
    '&.Mui-focused, &.MuiFormLabel-filled': {
      transform: 'translate(14px, -9px) scale(0.75)',
    },
  },
};

export default CustomerStatusStream;