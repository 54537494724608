import React from 'react';
import { Container, Grid, Typography, Paper, CircularProgress } from '@mui/material';
import { useFetchUserInformation } from './FetchUserInformation';
import AccountInformation from './AccountInformation';
import OverseasWarehouseAddresses from './OverseasWarehouseAddresses';

const UserInfo = () => {
  const { userAccount, loading, error } = useFetchUserInformation();

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  if (loading) {
    return (
      <Container maxWidth="lg" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!userAccount) {
    return <Typography>No user account data available.</Typography>;
  }

  return (
    <Container maxWidth="lg">
      <Grid container 
      spacing={4}>
        <Grid item xs={12} 
        md={6}
        >
          <Paper elevation={0} 
          sx={{ padding: 2 }}
          >
            <AccountInformation userAccount={userAccount} onCopy={copyToClipboard} loading={loading} />
          </Paper>
        </Grid>
        <Grid item xs={12} 
        md={6}
        >
          <Paper elevation={0}
          
          sx={{ padding: 2 }}>
            <OverseasWarehouseAddresses userAccount={userAccount} loading={loading} />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default UserInfo;