// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBanSaQskGkLir7XqzBIdAp87EbdE6GFF0",
  authDomain: "june-platforms.firebaseapp.com",
  projectId: "june-platforms",
  storageBucket: "june-platforms.appspot.com",
  messagingSenderId: "470101408849",
  appId: "1:470101408849:web:5f8b2ca9bb1b2f3b3c8c70",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;