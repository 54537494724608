import React from "react";
import "./profile.scss";
import Sidebar from "../../../components/sidebar/Sidebar"
import Topbar from "../../../components/topbar/Topbar";
import UserInfo from "../../../components/UserAccount/UserInfo";

const AdminProfile = () => {
  return (
    <div className="profile">
      <Sidebar />
      <div className="profile_container">
      
        <div className="top">
          <h2>Administrator Profile Details</h2>
        </div>
        <div className="userInfo">
          <UserInfo />

          
        </div>
      </div>
    </div>
  );
};

export default AdminProfile;
