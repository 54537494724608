import React, { useEffect, useState } from "react";
import "./custProdTable.scss";
import axios from "axios";
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Box, Typography, Grid, Paper } from "@mui/material";

function toTitleCase(text) {
  if (!text) return text;
  const acronyms = ['USA', 'EU', 'NASA', 'TV'];
  return text
    .split(' ')
    .map(word => {
      if (acronyms.includes(word.toUpperCase())) {
        return word.toUpperCase();
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
    })
    .join(' ');
}

const CustProdTable = () => {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const token = useSelector((state) => state.auth.user.accessToken);
  const [userData, setUserData] = useState(null);
  const [monthlyStats, setMonthlyStats] = useState({});

  const calculateMonthlyStats = (data) => {
    const stats = {};
    data.forEach(product => {
      const date = new Date(product.createdOn);
      const monthYear = `${date.getMonth() + 1}-${date.getFullYear()}`;
      if (!stats[monthYear]) {
        stats[monthYear] = { count: 0, weight: 0 };
      }
      stats[monthYear].count += 1;
      stats[monthYear].weight += parseFloat(product.weight) || 0;
    });
    return stats;
  };

  const fetchData = async () => {
    if (userData && userData.customer) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get(
          "/api/v1/user/warehouse/get/all/products",
          config
        );
        const filteredData = response.data.filter(
          (product) =>
            product.customerNumber === userData.customer.customerNumber
        );
        setTableData(filteredData);
        setMonthlyStats(calculateMonthlyStats(filteredData));
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (token) {
      const decodedToken = jwt_decode(token);
      axios
        .get(`/api/v1/user/get/by/email?email=${decodedToken.userEmail}`)
        .then((response) => {
          setUserData(response.data);
        })
        .catch((error) => {
          console.error("Error fetching user data: ", error);
        });
    }
  }, [token]);

  useEffect(() => {
    if (userData && userData.customer) {
      fetchData();
    }
  }, [userData]);

  const getCurrentMonthStats = () => {
    const now = new Date();
    const currentMonthYear = `${now.getMonth() + 1}-${now.getFullYear()}`;
    return monthlyStats[currentMonthYear] || { count: 0, weight: 0 };
  };

  const columns = [
    {
      accessorKey: "createdOn",
      header: "Date Added",
      Cell: ({ renderedCellValue }) => (
        <span>{new Date(renderedCellValue).toLocaleDateString()}</span>
      ),
    },
    {
      accessorKey: "description",
      header: "Description",
      Cell: ({ renderedCellValue }) => (
        <span>{toTitleCase(renderedCellValue)}</span>
      ),
    },
    {
      accessorKey: "supplierName",
      header: "Supplier Name",
    },
    {
      accessorKey: "weight",
      header: "Weight",
    },
    {
      accessorKey: "trackingNumber",
      header: "TID",
    },
    {
      accessorKey: "preAlert",
      header: "Status",
      Cell: ({ renderedCellValue }) => (
        <div>
          {renderedCellValue && renderedCellValue.startsWith("https")
            ? "Invoice Added"
            : "Invoice Missing"}
        </div>
      ),
    },
  ];

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    state: {
      isLoading,
    },
    muiSkeletonProps: {
      animation: 'wave',
    },
    enableStickyHeader: true,
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: '0px',
      },
    }
  });

  return (
    <Grid container spacing={3}>
      {/* <Grid item xs={12} md={6}>
        <Paper elevation={3} sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h6" gutterBottom component="div">
            Packages Shipped This Month: {getCurrentMonthStats().count}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper elevation={3} sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h6" gutterBottom component="div">
            Total Weight Shipped This Month: {getCurrentMonthStats().weight.toFixed(2)} lbs
          </Typography>
        </Paper>
      </Grid> */}
      <Grid item xs={12}>
        <Paper elevation={3} sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <MaterialReactTable 
            table={table}
            muiTablePaperProps={{
              elevation: 0,
              sx: { borderRadius: '0px' },
            }}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CustProdTable;