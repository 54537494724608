import React from "react";
import NavAppWarehouse from "../components/NavAppSidebar/NavAppWarehouse";
import Topbar from "../components/topbar/Topbar";
import "./wareDash.scss";
import Widget from "../components/widgets/Widget";
import ProductTable from "../components/Products/ProductTable/ProductTable";
import FooterNavApp from "./FooterNavApp/FooterNavApp";

const WareDash = () => {
  return (
    <div className="ware_home">
      <NavAppWarehouse />
      <div className="ware_container">
        {/* <div className="ware_widgets">
          <Widget type="products" className="ware_widget" />
        </div> */}
      </div>
      {/* <BottomBar /> */}
    </div>
  );
};

export default WareDash;
