import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Sidebar from "../../../WarehouseManagement/sideBar/SideBar";
import Topbar from "../../../components/topbar/Topbar";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import Logo from "../../../assets/JUNE-LOGO-3.png";
import QRCode from "react-qr-code";
// import Barcode from "react-jsbarcode";

const styles = {
  horizontalLine: {
    borderBottom: "3px solid #000000", // Black line, 2px width
    width: "90%", // Adjust as needed
    marginLeft: "5%",
    alignSelf: "stretch", // This will make it take up full height of the parent container
  },
  horizontalLineInContent: {
    borderBottom: "3px solid #000000", // Black line, 2px width
    width: "90%", // Adjust as needed
    marginRight: "10%",
    alignSelf: "stretch", // This will make it take up full height of the parent container
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    marginBottom: "10px",
    boxSizing: "border-box",
    // border: "4px solid #000000", // Example: 2px solid black border
    margin: "20px",
    fontSize: "20px",
  },
  right: {
    paddingTop: "0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center", // Center children vertically
    alignItems: "center", // Center children horizontally
    height: "100%", // Take up full height of the parent container
  },
  image: {
    width: "90px",
  },
  code: {
    paddingTop: "0",
    width: "100px",
  },
  manifest: {
    fontWeight: "bold",
  },
  manifestSpanPair: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
    marginBottom: "10px",
  },
  logo: {
    width: "200px",
  },
  logoAddressContainer: {
    display: "flex",
    alignItems: "center", // Align items vertically in the center
    gap: "5px", // Adjust this value as needed
    marginLeft: "-40px",
  },
  addManifest: {
    display: "flex",
    width: "100%",
  },
  addManifestContainer: {
    flex: 6,
    padding: "10px",
    margin: "20px",
    boxShadow: "2px 4px 10px 1px rgba(201, 201, 201, 0.47)",
  },
  top: {
    boxShadow: "2px 4px 10px 1px rgba(0, 0, 0, 0.47)",
    padding: "10px",
    margin: "20px",
    display: "flex",
  },
  h1: {
    color: "lightgray",
  },
  manifestData: {
    width: "8.5in", // Adjust width to fit the content
    minHeight: "11in", // Adjust height to fit the content
    padding: "1in", // Provide enough padding to match the form
    // boxShadow: "2px 4px 10px 1px rgba(0, 0, 0, 0.47)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center", // Center children horizontally
    justifyContent: "flex-start", // Align content to the top
    fontSize: "10pt", // Adjust font size to match the form's text
    lineHeight: "normal", // Adjust line height to natural reading
    fontFamily: "'Times New Roman', serif", // Font similar to the form
    // border: "2px solid #000000", // Border to resemble the form outline
    borderRadius: "10px", // Optional: if you want rounded corners
    marginTop: "20px",
    marginBottom: "20px",
  },
  manifestInfo: {
    marginTop: "20px",
    gap: "10px",
    display: "flex",
    flexDirection: "column",
    marginLeft: "40px",
  },
  printButton: {
    width: "150px",
    padding: "10px",
    border: "none",
    backgroundColor: "teal",
    color: "white",
    fontWeight: "bold",
    cursor: "pointer",
    marginTop: "10px",
  },
  manifestInput: {
    border: "1px solid grey",
    padding: "6px",
    width: "150px",
  },
};

const Manifest = () => {
  // const [productId, setProductId] = useState("");
  // const [barcodeData, setBarcodeData] = useState("");
  const [manifest, setManifest] = useState(null);
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.auth.user.accessToken);
  const componentRef = useRef();
  const location = useLocation();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Shipping Manifest",
    onAfterPrint: () => console.log("Printed PDF successfully!"),
  });

  // const cleanedToken = token.replace(/^"(.*)"$/, "$1");

  const productId = location.state?.id;
  const supplierName = location.state?.supplier;

  // Fetch manifest when component mounts and productId is available
  useEffect(() => {
    if (productId) {
      fetchManifest(productId); // Call fetchManifest with productId
    }
  }, [productId]); // Add productId as a dependency

  const fetchManifest = (productId) => {
    setLoading(true);

    const apiUrl = `/api/v1/user/warehouse/generate/shipping/manifest?productId=${productId}`;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(apiUrl, config)
      .then((response) => {
        setManifest(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  };
  return (
    <div style={styles.addManifest}>
      <Sidebar />
      <div style={styles.addManifestContainer}>
        <Topbar />
        <div style={styles.h1}>
          <h1>Generate Shipping Manifest</h1>
        </div>
        <div style={styles.manifestData} ref={componentRef}>
          {/* <div className="productDetails">
            <manifest htmlFor="">Enter Product Id: </manifest>
            <input
              type="text"
              id="productIdInput"
              value={productId}
              // onChange={handleInputChange}
              className="manifestInput"
            />
          </div>
          <button onClick={fetchManifest} className="fetch_button">
            Fetch Manifest
          </button> */}
          <div style={styles.contentContainer}>
            <div className="manifestInfo" style={styles.manifestInfo}>
              <div style={styles.logoAddressContainer}>
                <img src={Logo} alt="JUNE" style={styles.logo} />
                <p class="address">
                  JUNE Logistics Limited
                  <br />4 Ebony Road,
                  <br />
                  Kingston 11
                </p>
              </div>

              <div style={styles.horizontalLineInContent}></div>

              <div style={styles.manifestSpanPair}>
                <manifest style={styles.manifest} htmlFor="">
                  TO :
                </manifest>
                <span>
                  {manifest?.firstName} {manifest?.lastName}
                </span>
              </div>

              <div style={styles.manifestSpanPair}>
                <manifest style={styles.manifest} htmlFor="">
                  CUSTOMER NO. :{" "}
                </manifest>
                <span>{manifest?.customerNumber}</span>
              </div>

              <div style={styles.horizontalLineInContent}></div>

              <div style={styles.manifestSpanPair}>
                <manifest style={styles.manifest} htmlFor="">
                  DESCRIPTION:{" "}
                </manifest>
                <span>{manifest?.description}</span>
              </div>

              <div style={styles.manifestSpanPair}>
                <manifest style={styles.manifest} htmlFor="">
                  WEIGHT:{" "}
                </manifest>
                <span>{manifest?.weight}lbs</span>
              </div>

              <div style={styles.manifestSpanPair}>
                <manifest style={styles.manifest} htmlFor="">
                  SUPPLIER:{" "}
                </manifest>
                <span>{supplierName}</span>
              </div>
            </div>

            <div style={styles.horizontalLine}></div>
            <div style={styles.horizontalLine}></div>

            <div style={styles.right}>
              {/* <img
                src="https://cdn11.bigcommerce.com/s-10c6f/images/stencil/1280x1280/products/84212/167830/PL28054__49704.1686329481.jpg?c=2"
                alt=""
                style={styles.image}
              /> */}

              <QRCode
                size={200}
                style={styles.code}
                value={`Customer: ${manifest?.firstName} ${manifest?.lastName}, Customer Number: ${manifest?.customerNumber}, Package Description: ${manifest?.description}, Package Weight: ${manifest?.weight}, Supplier: ${supplierName}`}
              />
            </div>
          </div>
        </div>
        <button onClick={handlePrint} style={styles.printButton}>
          Print Manifest
        </button>
      </div>
    </div>
  );
};

export default Manifest;
