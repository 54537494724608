import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';

const UserInformationItem = ({ label, value, onCopy, isMobile }) => {


  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => setCopied(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [copied]);

  const handleCopy = () => {
    onCopy(value);
    setCopied(true);
  };

  return (

    <Box 
    display="flex" 
    flexDirection={isMobile ? "column" : "row"} 
    justifyContent="space-between" 
    alignItems={isMobile ? "flex-start" : "center"}
     >

    <Typography variant={isMobile ? "caption" : "h6"} fontWeight="bold">
      {label}
      </Typography>

    <Box display="flex" alignItems="center">

      <Typography variant={isMobile ? "caption" : "h7"}  

sx={{ wordBreak: "break-word" }}
      
      >{value}
      </Typography>

      <IconButton size="small" 
      onClick={handleCopy } 
  
      
      >

        {copied ? (
          <CheckIcon fontSize="small" sx={{ color: 'success.main' }}   />
        ) : (
          <ContentCopyIcon fontSize="small" />
          
        )}
      </IconButton>
    </Box>
  </Box>

      )

};

export default UserInformationItem;