import React from "react";
import "./widget.scss";
import { Link } from "react-router-dom";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ShoppingCartOutlineIcon from "@mui/icons-material/ShoppingCartOutlined";
import MonetizationOnOutlineIcon from "@mui/icons-material/MonetizationOnOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";

const Widget = ({ type }) => {
  let data;

  //temporary
  const amount = 100;
  const diff = 20;

  switch (type) {
    case "user":
      data = {
        title: "Customers",
        isMoney: false,
        link: "See all customers",
        linkPath: "/admin/users",
        icon: (
          <PersonOutlineIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2",
            }}
          />
        ),
      };
      break;
    case "customer":
      data = {
        title: "Customers",
        isMoney: false,
        link: "See all customers",
        linkPath: "/warehouse/dash/customers", // Path to navigate to when clicked
        icon: (
          <PersonOutlineIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2",
            }}
          />
        ),
      };
      break;
    case "orders":
      data = {
        title: "Orders",
        isMoney: false,
        link: "View all orders",
        linkPath: "/admin/orders/", // Path to navigate to when clicked
        icon: (
          <ShoppingCartOutlineIcon
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      break;
    case "products": //warehouse packages
      data = {
        title: "Packages",
        isMoney: false,
        link: "View All Packages",
        linkPath: "/warehouse/dash/products/viewProducts", // Path to navigate to when clicked
        icon: (
          <ShoppingCartOutlineIcon
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      break;
    case "packages": //admin packages
      data = {
        title: "Packages",
        isMoney: false,
        link: "View All Packages",
        linkPath: "/admin/all-customer-packages", // Path to navigate to when clicked
        icon: (
          <ShoppingCartOutlineIcon
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      break;
    case "earnings":
      data = {
        title: "EARNINGS",
        isMoney: false,
        link: "View net earnings",
        icon: (
          <MonetizationOnOutlineIcon
            className="icon"
            style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
          />
        ),
      };
      break;
    case "balance":
      data = {
        title: "BALANCE",
        isMoney: false,
        link: "See details",
        icon: (
          <AccountBalanceWalletOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "purple",
            }}
          />
        ),
      };
      break;
    default:
      break;
  }

  return (
    <div className="widget">
      <div className="left">
        <div className="title">{data.title}</div>
        <div className="counter">
          {data.isMoney && "$"} {amount}
        </div>
        <Link className="link" to={data.linkPath}>{data.link}</Link>
      </div>
      <div className="right">
        <div className="percentage positive">
          <KeyboardArrowUpIcon /> {diff} %
        </div>
        {data.icon}
      </div>
    </div>
  );
};

export default Widget;
