import React, { useEffect, useState, useCallback, useMemo } from "react";
import axios from "axios";
import "./CustomerAccountsView.scss";
import * as XLSX from 'xlsx';
import { 
  Button, Menu, MenuItem, Box, CircularProgress, Typography, IconButton,
  TableRow, TableCell
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useSelector } from "react-redux";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const CustomerAccountsView = () => {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEls, setAnchorEls] = useState({});

  const token = useSelector(state => state.auth.user.accessToken);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleActionClick = (event, rowId) => {
    setAnchorEls((prevState) => ({
      ...prevState,
      [rowId]: event.currentTarget,
    }));
  };

  const handleActionClose = (rowId) => {
    setAnchorEls((prevState) => ({
      ...prevState,
      [rowId]: null,
    }));
  };

  const handleDelete = useCallback(async (userId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this user?");
    if (!isConfirmed) return;

    try {
      const config = {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
      };
      await axios.delete(`/api/v1/user/${userId}`, config);
      setTableData(prevData => prevData.filter(item => item.user.id !== userId));
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  }, [token]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Invalid Date'; // Return a string for invalid dates
    }
    return date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  };

  const exportToExcel = () => {
    const filteredData = tableData.map(item => ({
      'Registration Date': formatDate(item.user.createdOn),
      'Customer Number': item.customerNumber,
      'Email Address': item.user.emailAddress,
      'Phone Number': item.user.phoneNumber,
      'First Name': item.user.firstName,
      'Last Name': item.user.lastName,
    }));
  
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    
    // Set the date column to have a date format in Excel
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const address = XLSX.utils.encode_col(C) + "1"; // Row 1
      if (worksheet[address].v === 'Registration Date') {
        for (let R = range.s.r + 1; R <= range.e.r; ++R) {
          const cell = XLSX.utils.encode_cell({c:C, r:R});
          if (worksheet[cell] && worksheet[cell].v !== 'Invalid Date') {
            worksheet[cell].t = 'd'; // Set cell type to date
            worksheet[cell].v = new Date(worksheet[cell].v); // Convert to Date object
            worksheet[cell].z = 'yyyy-mm-dd'; // Set date format
          }
        }
        break;
      }
    }
  
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Customers');
    XLSX.writeFile(workbook, 'customers.xlsx');
  };
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` } };
      const response = await axios.get("/api/v1/user/customer/get/all", config);
      const filteredData = response.data.filter(item => item.user.isActive);
      const sortedData = filteredData.sort((a, b) => {
        const dateA = new Date(a.user.createdOn);
        const dateB = new Date(b.user.createdOn);
        return dateB - dateA;
      });
      setTableData(sortedData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = useMemo(() => [
    {
      id: 'actions',
      header: 'Actions',
      Cell: ({ row }) => (
        <Box sx={{ position: 'relative' }}>
          <IconButton
            aria-controls={`action-menu-${row.id}`}
            aria-haspopup="true"
            onClick={(event) => handleActionClick(event, row.id)}
            sx={{ padding: '4px' }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id={`action-menu-${row.id}`}
            elevation={1}
            anchorEl={anchorEls[row.id]}
            open={Boolean(anchorEls[row.id])}
            onClose={() => handleActionClose(row.id)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={() => handleDelete(row.original.user.id)}>
              Delete
            </MenuItem>
            <MenuItem>
              Edit
            </MenuItem>
            <MenuItem>
              Share
            </MenuItem>
          </Menu>
        </Box>
      ),
      size: 50,
      minSize: 50,
      maxSize: 50,
    },
    {
      accessorKey: 'user.createdOn',
      header: 'Creation Date',
      Cell: ({ cell }) => {
        const date = new Date(cell.getValue());
        return date.toLocaleDateString();
      },
    },
    { accessorKey: 'customerNumber', header: 'Customer Number' },
    { accessorKey: 'user.firstName', header: 'First Name' },
    { accessorKey: 'user.lastName', header: 'Last Name' },
    { accessorKey: 'user.phoneNumber', header: 'Phone Number' },
    { accessorKey: 'user.emailAddress', header: 'Email Address' },
  ], [handleDelete, anchorEls]);

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    enableStickyHeader: true,
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: '#ffffff',
        zIndex: 1,
        fontFamily: 'Lato',
      },
    },
    muiTablePaperProps: {
      sx: {
        overflow: 'auto',
        borderRadius: 2,
      },
      elevation: 0,
    },
    initialState: {
      pagination: { pageIndex: 0, pageSize: 30 },
      sorting: [{ id: "dateAdded", desc: true }],
    },
  });

  return (
    <Box
      className={`table-container ${isLoading ? 'loading' : ''}`}
      sx={{
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: { xs: '0px', sm: '250px' }, // Add this line
        width: '100%',
      }}
    >
      <Box 
      sx={{
        width: '100%',
        // maxWidth: '500px', // Adjust this value as needed
        // padding: '20px',
      }}
      >
        <Typography variant="h6">
          Number of Customer Accounts: {tableData.length}
        </Typography>
      </Box>

      <Box sx={{ 
        width: '100%', 
        display: 'flex', 
        justifyContent: 'flex-start',
      }}>
        <Button
          variant="outlined"
          aria-controls="export-menu"
          aria-haspopup="true"
          onClick={handleClick}
          endIcon={<ArrowDropDownIcon />}
          sx={{
            textTransform: 'none',
            boxShadow: 'none',
          }}
        >
          Manage Customers
        </Button>

        <Menu
          id="export-menu"
          anchorEl={anchorEl}
          elevation={1}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuProps={{
            PaperProps: {
              sx: {
                boxShadow: 'none',
              },
            },
          }}
        >
          <MenuItem
            onClick={() => {
              exportToExcel();
              handleClose();
            }}
          >
            Export to Excel
          </MenuItem>
        </Menu>
      </Box>

      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
              zIndex: 1,
              padding: '10px',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          ></Box>

          <MaterialReactTable table={table} />
        </>
      )}
    </Box>
  );
};

export default CustomerAccountsView;