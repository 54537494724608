import * as React from "react";
import { useForm } from "react-hook-form";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { register } from "../../../features/auth/authSlice";
import {
  FormControl,
  InputLabel,
  RadioGroup,
  Radio,
  MenuItem,
  Select,
} from "@mui/material";
import ArrowForward from "@mui/icons-material/ArrowForward";

const API_URL = "https://june-backend-7alv.onrender.com"; // Replace with your actual API URL

function RegisterCourier() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = React.useState(false);
  const [policeRecordFileName, setPoliceRecordFileName] = React.useState("");
  const [driverLicenseFileName, setDriverLicenseFileName] = React.useState("");
  const [assessmentScore, setAssessmentScore] = React.useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(0);
  const [showQuestion, setShowQuestion] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState(null);
  const { register, handleSubmit, setValue } = useForm();
  const [showMessage, setShowMessage] = React.useState(false);

  const questions = [
    {
      question: "Do you own a smartphone? (iOS/Android)*",
      info: "You would be required to work extensively with smartphone applications so a certain level of comfort with technology is required.",
      options: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ],
    },
    {
      question: "Do you own a reliable car or motorcycle?*",
      info: "Please DO NOT APPLY if you do not have access to a car or bike.",
      options: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ],
    },
    {
      question: "Have you ever been convicted of a criminal offence?*",
      options: [
        { label: "Yes", value: "no" },
        { label: "No", value: "yes" },
      ],
    },
    {
      question: "Is your vehicle over 15 years old?*",
      options: [
        { label: "Yes", value: "no" },
        { label: "No", value: "yes" },
      ],
    },
    {
      question:
        "By submitting this form, you certify that information contained in this application is true and complete.*",
      info: "You also indicate that you understand that false information may be grounds for not hiring you or for immediate termination of contract/employment at any point in the future if you are hired. You are also agreeing to authorize the verification of any or all information listed above.",
      options: [
        { label: "I accept", value: "yes" },
        { label: "I don't accept", value: "no" },
      ],
    },
    // Add more questions in the same format
  ];

  const handleAnswer = (answer) => {
    if (answer) {
      setAssessmentScore((prevScore) => prevScore + 1);
    }
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setShowQuestion(true); // This line needs to be added
    } else {
      // Assessment is complete
      // Do something, like navigate to a different page or show results
      
    }
    // setShowQuestion(false); // To clear the screen
  };

  const renderQuestion = () => {
    const question = questions[currentQuestionIndex];
    const isLastQuestion = currentQuestionIndex === questions.length - 1;

    return (
      <Box sx={{ my: 4 }}>
        <Typography variant="h6">{question.question}</Typography>
        <Typography variant="body1">{question.info}</Typography>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label={question.question}
            name={question.name}
            value={selectedOption}
            onChange={(event) => {
              handleAnswer(event.target.value === "yes");
              setSelectedOption(event.target.value);
            }}
          >
            {question.options.map((option, index) => (
              <FormControlLabel
                key={index}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <Button
          variant="contained"
          endIcon={<ArrowForward />}
          onClick={isLastQuestion ? onSubmit : handleNextQuestion}
          sx={{ mt: 3 }}
        >
          {isLastQuestion ? "Submit" : "Next"}
        </Button>
      </Box>
    );
  };


  const handleArrowClick = () => {
    setFormSubmitted(false);
    setShowMessage(true);
  };

  const handleStartAssessment = () => {
    setShowMessage(false);
    setShowQuestion(true);
    setCurrentQuestionIndex(0); // Reset the question index if needed
    setAssessmentScore(0); // Reset the score if starting over
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setSelectedOption(""); // Reset the selected option
    } else {
      // Assessment is complete
      // Do something, like navigate to a different page or show results
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Submit
      </Button>; 
    }
  };

  if (showMessage) {
    return (
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            transition: "opacity 1s ease-out",
            opacity: showMessage ? 1 : 0,
          }}
        >
          <Typography component="h1" variant="h5">
            We're looking for Driver Partners in Kingston & St. Andrew and St.
            James who:
          </Typography>
          <Typography variant="body1" sx={{ mt: 2, mb: 2, textAlign: "left" }}>
            Are pleasant, professional and courteous
          </Typography>
          <Typography variant="body1" sx={{ mb: 2, textAlign: "left" }}>
            Own a reliable and fuel efficient car or motorcycle that is no more
            than 15 years old (no SUVs, pick-up trucks or mini-buses).
          </Typography>
          <Typography variant="body1" sx={{ mb: 2, textAlign: "left" }}>
            Own a smartphone (iPhone or Android)
          </Typography>
          <Typography variant="body2" sx={{ mt: 2, fontStyle: "italic" }}>
            (Please DO NOT apply on behalf of someone else. No exceptions.
            Please also have your motor vehicle documents ready as you will be
            required to upload them.)
          </Typography>
          <Button
            variant="contained"
            endIcon={<ArrowForward />}
            onClick={handleStartAssessment}
            sx={{ mt: 3 }}
          >
            I'm ready to Sign Up!
          </Button>
        </Box>
      </Container>
    );
  }

  if (showQuestion) {
    return renderQuestion();
  }

  async function onSubmit(data) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (data[key] instanceof FileList) {
        formData.append(key, data[key][0]);
      } else {
        formData.append(key, data[key]);
      }
    });

    // Append the assessmentScore to formData
    formData.append("assessmentScore", assessmentScore);

    try {
      const response = await dispatch(register(formData)).unwrap();
      const userId = response.userId;

      // Upload Police Record
      const policeRecordData = new FormData();
      policeRecordData.append("file", data.policeRecord[0]);
      await fetch(
        `${API_URL}/api/v1/user/courier/${userId}/upload/police/record`,
        {
          method: "PUT",
          body: policeRecordData,
        }
      );

      // Upload Driver's License
      const driverLicenseData = new FormData();
      driverLicenseData.append("file", data.driverLicense[0]);
      await fetch(
        `${API_URL}/api/v1/user/courier/${userId}/upload/drivers/license`,
        {
          method: "PUT",
          body: driverLicenseData,
        }
      );

      navigate("/login");
    } catch (error) {
      console.error("Registration error: ", error);
    }
  };



  const handleFileChange = (event) => {
    const { files } = event.target;
    const fieldName = event.target.name;
    if (files.length > 0) {
      const file = files[0];
      setValue(fieldName, files);
      if (fieldName === "policeRecord") {
        setPoliceRecordFileName(file.name);
      } else if (fieldName === "driverLicense") {
        setDriverLicenseFileName(file.name);
      }
    } else {
      setValue(fieldName, null);
    }
  };

  if (formSubmitted) {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            Delivery Partner Application Form
          </Typography>
          <Button
            variant="contained"
            endIcon={<ArrowForward />}
            onClick={handleArrowClick}
            sx={{ mt: 3 }}
          >
            Next
          </Button>
        </Box>
      </Container>
    );
  }

  return (
    <ThemeProvider theme={createTheme()}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Courier Registration
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstname"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  {...register("firstname")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastname"
                  autoComplete="family-name"
                  {...register("lastname")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  {...register("email")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  {...register("password")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="phoneNumber"
                  label="Phone Number"
                  name="phoneNumber"
                  autoComplete="tel"
                  {...register("phoneNumber")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="vehicleMake"
                  label="Vehicle Make"
                  name="vehicleMake"
                  autoComplete="vehicle-make"
                  {...register("vehicleMake")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="vehicleModel"
                  label="Vehicle Model"
                  name="vehicleModel"
                  autoComplete="vehicle-model"
                  {...register("vehicleModel")}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="vehicleType-label">Vehicle Type</InputLabel>
                  <Select
                    labelId="vehicleType-label"
                    id="vehicleType"
                    label="Vehicle Type"
                    name="vehicleType"
                    {...register("vehicleType")}
                  >
                    <MenuItem value="Car">Car</MenuItem>
                    <MenuItem value="Van">Van</MenuItem>
                    <MenuItem value="Motorcycle">Motorcycle</MenuItem>
                    <MenuItem value="Truck">Truck</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="licensePlateNumber"
                  label="License Plate Number"
                  name="licensePlateNumber"
                  autoComplete="license-plate"
                  {...register("licensePlateNumber")}
                />
              </Grid>
              <Grid item xs={12}>
                <input
                  accept=".pdf,image/*,.docx,.doc"
                  style={{ display: "none" }}
                  id="police-record-upload"
                  type="file"
                  {...register("policeRecord")}
                  onChange={handleFileChange}
                />
                <label htmlFor="police-record-upload">
                  <Button variant="contained" component="span">
                    Upload Police Record
                  </Button>
                </label>
                {policeRecordFileName && (
                  <Typography variant="subtitle1">
                    {policeRecordFileName}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <input
                  accept=".pdf,image/*,.docx,.doc"
                  style={{ display: "none" }}
                  id="driver-license-upload"
                  type="file"
                  {...register("driverLicense")}
                  onChange={handleFileChange}
                />
                <label htmlFor="driver-license-upload">
                  <Button variant="contained" component="span">
                    Upload Driver's License
                  </Button>
                </label>
                {driverLicenseFileName && (
                  <Typography variant="subtitle1">
                    {driverLicenseFileName}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox value="allowExtraEmails" color="primary" />
                  }
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
              </Grid>
            </Grid>
            {/* <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Take Assessment
            </Button> */}
            <Button
              type="button"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleArrowClick}
            >
              Take Assessment
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default RegisterCourier;
