import React, { useState, useRef } from "react";
import "./settings.scss";
import Sidebar from "../sidebar/Sidebar";
import Topbar from "../../topbar/Topbar";
import { useDispatch, useSelector } from "react-redux";

const Settings = () => {
  const [settingsData, setSettingsData] = useState({
    emailNotifications: false,
    smsNotifications: false,
    theme: "light",
  });

  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef();

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSettingsData({
      ...settingsData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Settings updated: ", settingsData);
    // Here, you can dispatch an action to update the settings
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleImageUpload = async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await fetch("/your-upload-endpoint", {
        method: "PUT", // or 'POST'
        body: formData,
      });
      // Handle the response
    } catch (error) {
      // Handle the error
    }
  };

  // Call handleImageUpload() where appropriate, such as after file selection or on a separate button click

  return (
    <div className="settings">
      <Sidebar />
      <div className="settings_container">
        <Topbar />
        <div className="top">
          <h1>Settings</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src="https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
              alt=""
              onClick={handleImageClick}
            />
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </div>
          <div className="right">
            <form className="settings_form" onSubmit={handleSubmit}>
              <div className="formInput">
                <label>Email Notifications</label>
                <input
                  type="checkbox"
                  name="emailNotifications"
                  checked={settingsData.emailNotifications}
                  onChange={handleInputChange}
                />
              </div>
              <div className="formInput">
                <label>SMS Notifications</label>
                <input
                  type="checkbox"
                  name="smsNotifications"
                  checked={settingsData.smsNotifications}
                  onChange={handleInputChange}
                />
              </div>
              <div className="formInput">
                <label>Theme</label>
                <select
                  name="theme"
                  value={settingsData.theme}
                  onChange={handleInputChange}
                >
                  <option value="light">Light</option>
                  <option value="dark">Dark</option>
                </select>
              </div>
              <button type="submit">Save Settings</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
