import React, { useEffect } from "react";
import "./waresidebar.scss";
import HomeIcon from "@mui/icons-material/Home";
import CardTravelIcon from "@mui/icons-material/CardTravel";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { logout, reset } from "../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../assets/JUNE-BGR.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUser, faCog } from '@fortawesome/free-solid-svg-icons';

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);

  const logoutHandler = async () => {
    try {
      await dispatch(logout()).unwrap();
      await dispatch(reset());
      navigate("/login");
    } catch (error) {
      console.error("An error occurred during logout: ", error);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  return (
    <div className="Sidebar">
      <div className="logo">
        {/* <Link to="/">
          <img src={Logo} alt="JUNE" />
        </Link> */}
      </div>
      <div className="center">
        <ul className="dash_links">
          <Link to="/warehouse/dash">
            <li>
              <FontAwesomeIcon icon={faHome} className="fa-icon" />
            </li>
          </Link>
          <Link to="/warehouse/dash/products">
            <li>
              <CardTravelIcon className="icon" />
            </li>
          </Link>
          <li onClick={logoutHandler}>
            <ExitToAppIcon className="icon" />
          </li>
        </ul>
      </div>

    </div>
  );
};

export default Sidebar;

