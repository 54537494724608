import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import "./label.scss";
import Sidebar from "../../../WarehouseManagement/sideBar/SideBar";
import Topbar from "../../../components/topbar/Topbar";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import Logo from "../../../assets/JUNE-LOGO-3.png";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";
import { fontSize } from "@mui/system";
// import Barcode from "react-jsbarcode";

const styles = {
  horizontalLine: {
    borderBottom: "3px solid #000000", // Black line, 2px width
    width: "90%", // Adjust as needed
    marginLeft: "5%",
    alignSelf: "stretch", // This will make it take up full height of the parent container
  },
  horizontalLineInContent: {
    borderBottom: "3px solid #000000", // Black line, 2px width
    width: "90%", // Adjust as needed
    marginRight: "10%",
    alignSelf: "stretch", // This will make it take up full height of the parent container
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    marginBottom: "10px",
    boxSizing: "border-box",
    border: "4px solid #000000", // Example: 2px solid black border
    margin: "20px",
    fontSize: "15px",
  },
  right: {
    paddingTop: "0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center", // Center children vertically
    alignItems: "center", // Center children horizontally
    height: "100%", // Take up full height of the parent container
  },
  image: {
    width: "90px",
  },
  code: {
    marginBottom: "10px",
    width: "100px",
  },
  label: {
    fontWeight: "bold",
  },
  labelSpanPair: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
    marginBottom: "10px",
  },
  logo: {
    width: "200px",
  },
  logoAddressContainer: {
    display: "flex",
    alignItems: "center", // Align items vertically in the center
    gap: "5px", // Adjust this value as needed
    marginLeft: "-40px",
    fontSize: "15px",
  },
  addLabel: {
    display: "flex",
    width: "100%",
  },
  addLabelContainer: {
    flex: 6,
    padding: "10px",
    margin: "20px",
    boxShadow: "2px 4px 10px 1px rgba(201, 201, 201, 0.47)",
  },
  top: {
    boxShadow: "2px 4px 10px 1px rgba(0, 0, 0, 0.47)",
    padding: "10px",
    margin: "20px",
    display: "flex",
  },
  h1: {
    color: "rgb(74, 74, 74)",
  },
  labelData: {
    width: "3.8in", // 4 inches wide
    height: "5.8in", //6 inches high
    padding: "0px", // Increase padding as needed
    boxShadow: "2px 4px 10px 1px rgba(0, 0, 0, 0.47)",
    margin: "20px",
    display: "flex",
    flexDirection: "column",
    borderRadius: "2%",
    alignItems: "center", // Vertically center children
    justifyContent: "space-between", // Space out children
  },
  labelInfo: {
    marginTop: "20px",
    gap: "10px",
    display: "flex",
    flexDirection: "column",
    marginLeft: "40px",
  },
  printButton: {
    width: "400px",
    padding: "10px",
    border: "none",
    backgroundColor: "green",
    color: "white",
    fontWeight: "bold",
    cursor: "pointer",
    marginTop: "10px",
    marginLeft: "10px",
    marginRight: "auto",
  },
  labelInput: {
    border: "1px solid grey",
    padding: "6px",
    width: "150px",
  },
};

const Label = () => {
  // const [productId, setProductId] = useState("");
  // const [barcodeData, setBarcodeData] = useState("");
  const [label, setLabel] = useState(null);
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.auth.user.accessToken);
  const componentRef = useRef();
  const location = useLocation();

  let navigate = useNavigate();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Shipping Label",
    onAfterPrint: () => navigate(-1),
  });

  // const cleanedToken = token.replace(/^"(.*)"$/, "$1");

  const productId = location.state?.id;
  const supplierName = location.state?.supplier;
  const description = location.state?.description;
  const weight = location.state?.weight;
  const customerNumber = location.state?.customerNumber;
  const firstName = location.state?.firstName;
  const lastName = location.state?.lastName;


  // Fetch label when component mounts and productId is available
  useEffect(() => {
    if (productId) {
      fetchLabel(productId); // Call fetchLabel with productId
    }
  }, [productId]); // Add productId as a dependency

  const fetchLabel = (productId) => {
    setLoading(true);

    const apiUrl = `/api/v1/user/warehouse/generate/shipping/label?productId=${productId}`;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(apiUrl, config)
      .then((response) => {
        setLabel(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  };

  return (
    <div style={styles.addLabel}>
      <Sidebar />
      <div style={styles.addLabelContainer}>
        <Topbar />
        <div className="label-header">
          <button onClick={() => navigate(-1)}>
            {" "}
            <img
              src="https://static.vecteezy.com/system/resources/thumbnails/002/205/875/small/backward-arrow-icon-free-vector.jpg"
              alt="Back"
              id="back-button"
            />{" "}
          </button>

          <div className="title" style={styles.h1}>
            <h1>Generate Shipping Label</h1>
          </div>
        </div>

        <div
          className="label-container"
          style={styles.labelData}
          ref={componentRef}
        >
          <div style={styles.contentContainer}>
            <div className="labelInfo" style={styles.labelInfo}>
              <div style={styles.logoAddressContainer}>
                <img src={Logo} alt="JUNE" style={styles.logo} />
                <p class="address">
                  JUNE Logistics Limited
                  <br />4 Ebony Road,
                  <br />
                  Kingston 11
                </p>
              </div>

              <div style={styles.horizontalLineInContent}></div>

              <div style={styles.labelSpanPair}>
                <label style={styles.label} htmlFor="">
                  TO :
                </label>
                <span>
                  {firstName} {lastName}
                </span>
              </div>

              <div style={styles.labelSpanPair}>
                <label style={styles.label} htmlFor="">
                  CUSTOMER NO. :{" "}
                </label>
                <span>{customerNumber}</span>
              </div>

              <div style={styles.horizontalLineInContent}></div>

              <div style={styles.labelSpanPair}>
                <label style={styles.label} htmlFor="">
                  DESCRIPTION:{" "}
                </label>
                <span>{description}</span>
              </div>

              <div style={styles.labelSpanPair}>
                <label style={styles.label} htmlFor="">
                  WEIGHT:{" "}
                </label>
                <span>{weight}lbs</span>
              </div>

              <div style={styles.labelSpanPair}>
                <label style={styles.label} htmlFor="">
                  SUPPLIER:{" "}
                </label>
                <span>{supplierName}</span>
              </div>
            </div>

            <div style={styles.horizontalLine}></div>
            <div style={styles.horizontalLine}></div>

            <div style={styles.right}>
              {/* <img
                src="https://cdn11.bigcommerce.com/s-10c6f/images/stencil/1280x1280/products/84212/167830/PL28054__49704.1686329481.jpg?c=2"
                alt=""
                style={styles.image}
              /> */}

              <QRCode
                size={100}
                style={styles.code}
                value={`Customer: ${firstName} ${lastName}, Customer Number: ${customerNumber}, Package Description: ${description}, Package Weight: ${weight}, Supplier: ${supplierName}`}
              />
            </div>
          </div>
        </div>
        <button
          id="print-label-button"
          onClick={handlePrint}
          style={styles.printButton}
        >
          Print Label
        </button>
      </div>
    </div>
  );
};

export default Label;
