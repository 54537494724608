import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    background: {
      default: "#ffffff", // Define your default background color here
      fontFamily: '"Lato"',

    },
  },

  typography: {
    fontFamily: '"Lato"',
    h1: {
      fontFamily: '"Lato"',
    },
    h2: {
      fontFamily: '"Lato"',
    },
    body1: {
      fontFamily: '"Lato"',
      fontSize: '0.9rem', // Define the default font size for body text here

    },
    body2: {
      fontFamily: '"Lato"',
    },
  },
  // Other theme configurations
});
