import React, { useState } from "react";
import "./settings.scss";
import Sidebar from "../../WarehouseManagement/sideBar/SideBar";
import Topbar from "../topbar/Topbar";
import { useDispatch, useSelector } from "react-redux";

const Settings = () => {
  // You can replace this state with whatever settings you need to manage
  const [settingsData, setSettingsData] = useState({
    emailNotifications: false,
    smsNotifications: false,
    theme: "light",
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSettingsData({
      ...settingsData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Submit your settings data
    console.log("Settings updated: ", settingsData);
  };

  return (
    <div className="settings">
      <Sidebar />
      <div className="settings_container">
        <Topbar />
        <div className="top">
          <h1>Settings</h1>
        </div>
        <div className="bottom">
          <div className="left">
            {/* Placeholder for settings icon or image */}
            <img
              src="https://static.thenounproject.com/png/204868-200.png"
              alt=""
            />
          </div>
          <div className="right">
            <form className="settings_form" onSubmit={handleSubmit}>
              <div className="formInput">
                <label>Email Notifications</label>
                <input
                  type="checkbox"
                  name="emailNotifications"
                  checked={settingsData.emailNotifications}
                  onChange={handleInputChange}
                />
              </div>
              <div className="formInput">
                <label>SMS Notifications</label>
                <input
                  type="checkbox"
                  name="smsNotifications"
                  checked={settingsData.smsNotifications}
                  onChange={handleInputChange}
                />
              </div>
              <div className="formInput">
                <label>Theme</label>
                <select
                  name="theme"
                  value={settingsData.theme}
                  onChange={handleInputChange}
                >
                  <option value="light">Light</option>
                  <option value="dark">Dark</option>
                </select>
              </div>
              <button type="submit">Save Settings</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
