import React, { useState } from "react";
import Sidebar from "../../components/customers/sidebar/Sidebar";
import Topbar from "../../components/topbar/Topbar";
import axios from "axios";
import "./invoiceUpload.scss"; // Assuming you have a CSS file for styling
import Button from "@mui/material/Button";
import FreightTypeSelection  from "./FreightTypeSelection"
import CustProdTable from "./ProductTable/CustProdTable";
import InvoiceUploadModal from "./InvoiceUploadModal";
import BottomBar from "./bottombar/BottomBar";
import NavAppCustomer from '../NavAppSidebar/NavAppCustomer';



const InvoiceUpload = ({ productId, closeModal }) => {
  

  return (
    <div className="profile">
      <NavAppCustomer />
      {/* <Sidebar/> */}
      <div className="profile_container">
        {/* <Topbar /> */}
        <CustProdTable />
      </div>
      <BottomBar />
    </div>
  );
};

export default InvoiceUpload;
