import React from 'react';
import { Paper, Grid, Typography, Box, Skeleton } from '@mui/material';
import UserInformationItem from './UserInformationItem';

const AccountInformationSkeleton = () => (
  <Paper elevation={0} sx={{ padding: 2, border: '1px solid #e0e0e0' }}>
    <Skeleton variant="text" width="40%" height={40} sx={{ mb: 2 }} />
    <Grid container spacing={2}>
      {[...Array(4)].map((_, index) => (
        <Grid item xs={12} key={index} sx={{ border: '1px dashed #bdbdbd', padding: 1 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Skeleton variant="text" width="30%" />
            <Skeleton variant="text" width="60%" />
          </Box>
        </Grid>
      ))}
    </Grid>
  </Paper>
);

const AccountInformation = ({ userAccount, onCopy, loading }) => {
  if (loading) {
    return <AccountInformationSkeleton />;
  }

  return (
    <Paper 
      elevation={0} 
      sx={{ 
        padding: 3, 
        // border: '1px solid #e0e0e0',
      }}
    >
      {/* <Typography variant="h3" >Account Information</Typography> */}
      <Grid container
       spacing={1}
       padding={2}
      >
        <Grid item xs={12} 
        sx={{ border: '1px dashed #bdbdbd', 
        padding: 1
         }}>
          <UserInformationItem 
            label="Full Name"
            value={`${userAccount.customer.user.firstName} ${userAccount.customer.user.lastName}`}
            onCopy={onCopy}
          />
        </Grid>
        <Grid item xs={12} sx={{ border: '1px dashed #bdbdbd', padding: 1 }}>
          <UserInformationItem 
            label="Account Number"
            value={userAccount.customer.customerNumber}
            onCopy={onCopy}
          />
        </Grid>
        <Grid item xs={12} sx={{ border: '1px dashed #bdbdbd', padding: 1 }}>
          <UserInformationItem 
            label="Contact Number"
            value={userAccount.customer.user.phoneNumber}
            onCopy={onCopy}
          />
        </Grid>
        <Grid item xs={12} sx={{ border: '1px dashed #bdbdbd', padding: 1 }}>
          <UserInformationItem 
            label="Email"
            value={userAccount.customer.user.emailAddress}
            onCopy={onCopy}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default AccountInformation;