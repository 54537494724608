import React from "react";
import Topbar from "../../../components/topbar/Topbar";
import Sidebar from "../../../WarehouseManagement/sideBar/SideBar";
import ProductTable from "../../../components/Products/ProductTable/ProductTable";
import { DataGrid } from "@mui/x-data-grid";
import BottomBar from "../../../WarehouseManagement/FooterNavApp/FooterNavApp";
import NavAppWarehouse from "../../../components/NavAppSidebar/NavAppWarehouse";
import "./WarehouseManifest.scss";

const WarehouseManifest = () => {
  return (
    <div className="warehouse-manifest-layout">
      <NavAppWarehouse />
      <div className="warehouse-manifest-content">
        <ProductTable />
      </div>
    </div>
  );
};

export default WarehouseManifest;