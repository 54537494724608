import React, { useEffect, useState } from "react";
import "./productTable.scss";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CircularProgress,
  Box,
  MenuItem,
  Select,
  Modal,
  Button,
  TextField,
  Typography,
  Grid,
  FormControl,
  FormLabel,
} from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import { useForm, Controller } from "react-hook-form";

const ProductTable = () => {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [editData, setEditData] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const token = useSelector((state) => state.auth.user.accessToken);
  const navigate = useNavigate();

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      supplierName: "",
      customerNumber: "",
      weight: "",
      description: "",
      trackingNumber: "",
    },
  });

  const fetchData = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        "/api/v1/user/warehouse/get/all/products",
        config
      );
      setTableData(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleLabel = (params) => {
    navigate("/warehouse/dash/shipments/label/", {
      state: {
        id: params.junId,
        supplier: params.supplierName,
        description: params.description,
        weight: params.weight,
        firstName: params.customerFirstName,
        lastName: params.customerLastName,
        customerNumber: params.customerNumber,
      },
    });
  };

  const handleEdit = (params) => {
    setEditData(params);
    setIsEditModalOpen(true);
    setValue("supplierName", params.supplierName);
    setValue("customerNumber", params.customerNumber);
    setValue("weight", params.weight);
    setValue("description", params.description);
    setValue("trackingNumber", params.trackingNumber);
  };

  const handleAction = (params, action) => {
    if (action === "label") {
      handleLabel(params);
    } else if (action === "edit") {
      handleEdit(params);
    }
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      await axios.put(
        `/api/v1/user/warehouse/update/product?productId=${editData.junId}`,
        data,
        config
      );
      fetchData();
      setIsEditModalOpen(false);
    } catch (error) {
      console.error("Error updating data:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const columns = [
    { accessorKey: "junId", header: "ID", size: 70 },
    {
      accessorKey: "action",
      header: "Action",
      Cell: ({ row }) => (
        <Select
          value=""
          onChange={(e) => handleAction(row.original, e.target.value)}
          displayEmpty
          inputProps={{ "aria-label": "select an action" }}
        >
          <MenuItem value="" disabled>
            Select Action
          </MenuItem>
          <MenuItem value="label">Create New Label</MenuItem>
          <MenuItem value="edit">Edit</MenuItem>
        </Select>
      ),
    },
    { accessorKey: "supplierName", header: "Supplier Name", size: 130 },
    { accessorKey: "customerNumber", header: "Customer Number", size: 150 },
    { accessorKey: "weight", header: "Weight", size: 130 },
    { accessorKey: "description", header: "Description", size: 130 },
    { accessorKey: "trackingNumber", header: "Tracking Number", size: 200 },
  ];

  const filteredData = searchKeyword
    ? tableData.filter((row) =>
        Object.values(row).some((value) =>
          value?.toString().toLowerCase().includes(searchKeyword.toLowerCase())
        )
      )
    : tableData;

  return (
    <Box 
    className="table-content"
    // sx={{
    //   display: 'flex',
    //   justifyContent: { xs: 'center', sm: 'flex-start' },
    //   width: '100%',
    // }}
    >
      <TextField
  type="text"
  placeholder="Search your packages"
  value={searchKeyword}
  onChange={(e) => setSearchKeyword(e.target.value)}
  variant="outlined"
  size="small"
  fullWidth
  sx={{
    margin: { xs: '10px 5px', sm: '10px' },
    width: { xs: '95%', sm: '600px' },
    width: { xs: '80%', sm: '400px' },
    '& .MuiOutlinedInput-root': {
      borderRadius: '5px',
      padding: { xs: '8px 15px', sm: '10px 30px 10px 30px' },
    },
    '& .MuiOutlinedInput-input': {
      fontSize: { xs: '14px', sm: '16px' },
    },
  }}
/>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress style={{ color: "green" }} />
        </Box>
      ) : (
        <MaterialReactTable
          columns={columns}
          data={filteredData}
          getRowId={(row) => row.junId}
          enableSorting={true}
          enablePagination={true}
          muiTableBodyRowProps={{ hover: true }}
          muiTablePaperProps={{
            sx: {
              maxHeight: "calc(100% - 64px)",
              overflow: "auto",
              marginBottom: "20px",
              marginLeft: 0.9,
            },
            elevation: 0,
          }}
          enableStickyHeader={true}
          muiTableHeadCellProps={{
            elevation: 0,
          }}
        />
      )}

<Modal open={isEditModalOpen} onClose={() => setIsEditModalOpen(false)}>
  <Box
    component="form"
    onSubmit={handleSubmit(onSubmit)}
    sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: {
        xs: '90%', // 90% of the viewport width on extra-small screens
        sm: 400,   // 400px on small screens and above
        md: 600,   // 600px on medium screens and above
        lg: 800,   // 800px on large screens and above
      },
      bgcolor: "background.paper",
      border: "1px solid #e0e0e0", // Thinner border
      boxShadow: "none", // Remove shadow
      p: 2, // Reduced padding
      borderRadius: 2, // Border radius for rounded corners
    }}
  >
          <Typography variant="h6" component="h2" mb={2}>
            Edit Product
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel>Supplier Name</FormLabel>
                <Controller
                  name="supplierName"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                  rules={{ required: "Supplier Name is required" }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel>Customer Number</FormLabel>
                <Controller
                  name="customerNumber"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                  rules={{ required: "Customer Number is required" }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel>Weight</FormLabel>
                <Controller
                  name="weight"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                  rules={{ required: "Weight is required" }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel>Description</FormLabel>
                <Controller
                  name="description"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                  rules={{ required: "Description is required" }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel>Tracking Number</FormLabel>
                <Controller
                  name="trackingNumber"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                  rules={{ required: "Tracking Number is required" }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={3}>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                fullWidth
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <CircularProgress size={24} sx={{ color: "white" }} />
                ) : (
                  "Save Changes"
                )}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                onClick={() => setIsEditModalOpen(false)}
                fullWidth
                disabled={isSubmitting}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};

export default ProductTable;
