import React from "react";
import Topbar from "../../../components/topbar/Topbar";
import Sidebar from "../../sidebar/Sidebar";
import "./products.scss";
import AdminProductTable from "../ProductTable/ProductTable";
import { DataGrid } from "@mui/x-data-grid";
import BottomBar from "../bottombar/BottomBar";
import Shipments from "./shipments";

const AdminProducts = () => {
  return (
    <div className="view_products">
      <Sidebar />
      <div className="view_container">
        <Topbar />
        <Shipments />
        {/* <AdminProductTable /> */}
      </div>
      <BottomBar />
    </div>
  );
};

export default AdminProducts;
