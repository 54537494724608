
import ShipmentCard from "./shipmentCard";
import React, { useEffect, useState } from "react";

import {
  getStorage,
  ref,
  listAll,
  getMetadata,
  getDownloadURL,
} from "firebase/storage";
import AdminProductTable from "../ProductTable/ProductTable";
import "./shipments.scss";
import * as XLSX from "xlsx";

// Initialize Firebase Storage
const storage = getStorage();

const fetchShipments = async () => {
  const shipmentsRef = ref(storage, "Shipments");
  const listResult = await listAll(shipmentsRef);
  const shipments = await Promise.all(
    listResult.items.map(async (itemRef) => {
      const metadata = await getMetadata(itemRef);
      const name = metadata.name;
      const updatedAt = metadata.updated;

      const match = name.match(/\[(\d+)\]/);
      const shipmentId = match ? match[1] : "ID NOT KNOWN";

      return { name, shipmentId, updatedAt, itemRef };
    })
  );
  return shipments;
};

const Shipments = () => {
  const [shipments, setShipments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [view, setView] = useState("shipments");
  const [filterIds, setFilterIds] = useState([]);

  useEffect(() => {
    const getShipments = async () => {
      const shipmentsData = await fetchShipments();
      setShipments(shipmentsData);
      setIsLoading(false);
    };
    getShipments();
  }, []);

  const handleCardClick = async (shipment) => {
    try {
      const url = await getDownloadURL(shipment.itemRef);
      const response = await fetch(url);
      const data = await response.arrayBuffer();
      const workbook = XLSX.read(data, { type: "array" });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(sheet);

      // Log the entire sheet data for inspection
      console.log("Sheet data:", jsonData);

      // Use the correct column names
      const shipmentColumn1 = `SHIPMENT [${shipment.shipmentId}]`;
      const shipmentColumn2 = `SHIPMENT[${shipment.shipmentId}]`;   

      // Check if JUNID column exists
      const junidExists =
        jsonData.length > 0 && jsonData[0].hasOwnProperty("JUNID");
      const shipmentId1Exists =
        (jsonData.length > 0 && jsonData[0].hasOwnProperty(shipmentColumn1));
        const shipmentId2Exists = (jsonData.length > 0 && jsonData[0].hasOwnProperty(shipmentColumn2));

      if (!junidExists && !shipmentId1Exists && !shipmentId2Exists) {
        throw new Error(`Neither JUNID nor ${shipmentColumn1} nor ${shipmentColumn2} found in headers`);
      }

      // Extract the relevant IDs
      const ids = jsonData
        .map((row) => {
          if (junidExists) return row["JUNID"];
          if (shipmentId1Exists) return row[shipmentColumn1];
          if (shipmentId2Exists) return row[shipmentColumn2];
          return undefined;
        })
        .filter((id) => id !== undefined);

      console.log("Extracted IDs:", ids); // Debug log
      setFilterIds(ids);
      setView("products");
    } catch (error) {
      console.error("Error loading shipment file:", error);
    }
  };


  const handleBackClick = () => {
    setView("shipments");
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {view === "shipments" ? (
        <div className="shipments-grid">
          {shipments.map((shipment) => (
            <button key={shipment.name} onClick={() => handleCardClick(shipment)}>
              <ShipmentCard
                shipmentId={shipment.shipmentId}
                updatedAt={shipment.updatedAt}
              />
            </button>
          ))}
        </div>
      ) : (
        <div>
          <button id="backtoship" onClick={handleBackClick}>Back to Shipments</button>
          {view === "products" && <AdminProductTable filterIds={filterIds} />}
        </div>
      )}
    </div>
  );
};

export default Shipments;
